import React from "react";

import HomeServices from "../components/home/HomeServices";
import HomeNewsletter from "../components/home/HomeNewsletter";
import BecomePartner from "../components/global/BecomePartner";
import ForagerGivesBack from "../components/global/ForagerGivesBack";

const HomeScreen = () => {
  return (
    <div className="container">
      <HomeNewsletter />
      <HomeServices />
      <BecomePartner isContact />
      <ForagerGivesBack />
    </div>
  );
};

export default HomeScreen;
