const descriptions = [
  {
    pathname: "/",
    title:
      "Forager Digital | Your Creative Partner for Tourism, Arts & Culture Marketing",
    content: [
      "Discover how Forager Digital can elevate your brand in the tourism, arts, and culture sectors with innovative marketing solutions. Let's craft compelling stories together!",
    ],
    keywords: [
      "Tourism marketing",
      "Arts marketing",
      "Culture marketing",
      "Creative marketing agency",
      "Digital marketing solutions",
      "Brand elevation",
      "Compelling storytelling",
      "Tourism branding",
      "Arts promotion",
      "Cultural outreach",
      "Marketing innovation",
      "Brand partnership",
      "Digital storytelling",
      "Tourism industry",
      "Arts and culture promotion",
      "Marketing collaboration",
      "Tourism advertising",
      "Cultural marketing strategies",
      "Brand engagement",
      "Marketing consultancy",
    ],
  },
  {
    pathname: "/about",
    title: "About Forager Digital | Meet Our Team of Creative Minds",
    content: [
      "Learn about Forager Digital, a dynamic creative agency passionate about helping organizations in tourism, arts, and culture thrive. Meet our team and discover our journey.",
    ],
    keywords: [
      "Creative agency",
      "Team of creative minds",
      "Tourism industry expertise",
      "Arts and culture enthusiasts",
      "Agency mission",
      "Team introduction",
      "Agency journey",
      "Creative team members",
      "Passionate professionals",
      "Company culture",
      "Creative vision",
      "Team profiles",
      "Agency values",
      "Creative storytelling",
      "Company ethos",
      "Creative collaboration",
      "Agency background",
      "Team diversity",
      "Agency philosophy",
      "Team achievements",
    ],
  },
  {
    pathname: "/services",
    title: "Our Services | Creative Marketing Solutions for Tourism & Culture",
    content: [
      "Explore Forager Digital's comprehensive services tailored for organizations in tourism, arts, culture, and heritage. From branding to digital marketing, we've got you covered.",
    ],
    keywords: [
      "Creative marketing solutions",
      "Tourism marketing services",
      "Arts marketing solutions",
      "Culture branding",
      "Heritage promotion",
      "Digital marketing services",
      "Branding solutions",
      "Tourism advertising",
      "Arts promotion services",
      "Cultural outreach",
      "Marketing strategy",
      "Digital storytelling",
      "Brand development",
      "Content marketing",
      "Social media marketing",
      "Website design",
      "SEO services",
      "Email marketing",
      "Event promotion",
      "Analytics and reporting",
    ],
  },
  {
    pathname: "/industries",
    title: "Industries We Serve | Tourism, Arts & Culture Marketing Agency",
    content: [
      "Forager Digital specializes in providing marketing, design, and web services for organizations in the tourism, arts, culture, and heritage sectors. Discover how we can help your industry thrive.",
    ],
    keywords: [
      "Tourism marketing agency",
      "Arts marketing agency",
      "Culture marketing services",
      "Heritage sector",
      "Industry-specific marketing",
      "Tourism promotion",
      "Arts advocacy",
      "Cultural branding agency",
      "Heritage marketing solutions",
      "Sector-focused marketing",
      "Industry expertise",
      "Tourism industry promotion",
      "Arts sector marketing",
      "Cultural heritage branding",
      "Industry-specific design",
      "Tourism web services",
      "Arts marketing strategies",
      "Cultural sector promotion",
      "Heritage web design",
      "Industry partnerships",
    ],
  },
  {
    pathname: "/blogs",
    title: "Explore Our Blog | Insights for Tourism & Culture Professionals",
    content: [
      "Dive into Forager Digital's blog catalogue for valuable insights, tips, and trends in marketing, design, and web services tailored for professionals in the tourism, arts, and culture sectors.",
    ],
    keywords: [
      "Tourism insights",
      "Arts trends",
      "Culture marketing tips",
      "Blog catalogue",
      "Marketing insights",
      "Design trends",
      "Web services tips",
      "Tourism professionals",
      "Arts industry news",
      "Cultural marketing strategies",
      "Blog articles",
      "Marketing trends",
      "Design tips",
      "Web services trends",
      "Tourism marketing advice",
      "Arts promotion ideas",
      "Culture industry updates",
      "Blog posts",
      "Marketing resources",
      "Industry trends analysis",
    ],
  },
  {
    pathname: "/contact",
    title: "Contact Forager Digital | Let's Start Crafting Your Story",
    content: [
      "Get in touch with Forager Digital today to discuss your marketing needs in the tourism, arts, and culture sectors. Let's collaborate and bring your vision to life.",
    ],
    keywords: [
      "Contact Forager Digital",
      "Tourism marketing consultation",
      "Arts marketing inquiry",
      "Culture sector collaboration",
      "Marketing needs discussion",
      "Contact information",
      "Collaboration opportunities",
      "Inquiry form",
      "Tourism industry partnership",
      "Arts sector engagement",
      "Culture marketing consultation",
      "Marketing solutions discussion",
      "Contact details",
      "Collaboration request",
      "Vision discussion",
      "Partnership inquiry",
      "Marketing strategy consultation",
      "Arts promotion collaboration",
      "Culture marketing inquiry",
      "Vision realization",
    ],
  },
  //   {
  //     pathname: "/",
  //     content: [""],
  //     keywords: [""],
  //   },
];

export default descriptions;
