import React from "react";

import mail from "../assets/img/contact/mail.svg";

import BecomePartner from "../components/global/BecomePartner";
import ContactForm from "../components/contact/ContactForm";
import SocialLinks from "../components/SocialLinks";

const ContactScreen = () => {
  return (
    <div className="mt-8 pt-[100px]">
      <h1 className="text-orange mx-auto capitalize mb-8 text-center md:w-[500px] lg:w-[800px] mb-16">
        Get in touch with us!
      </h1>
      <div className="container grid lg:grid-cols-2 gap-5 lg:gap-10 xl:gap-20">
        <div>
          <div className="grid gap-8">
            <p>
              If you require assistance with marketing, branding, or web design,
              we're eager to explore{` `}
              <span className="font-bold">how we can offer our support.</span>
            </p>
            <p>
              For partnership opportunities and general inquiries, feel free to
              reach out to us:
            </p>
            <div className="flex gap-2">
              <img src={mail} alt="" />
              <a
                href="mailto:info@foragermediagroup.com"
                target="_blank"
                rel="noreferrer"
                className="font-bold"
              >
                info@foragermediagroup.com
              </a>
            </div>
          </div>
          <div className="my-16 lg:my-36">
            <h4 className="text-orange">Connect with Us Online</h4>
            <p className="my-8">
              Stay updated on the latest financial insights, industry news, and
              <span className="block">
                Forager happenings by connecting with us on our social media
                channels:
              </span>
            </p>
            <SocialLinks iconClassName={"text-orange hover:text-denim"} />
          </div>
        </div>
        <div>
          <span className="block lg:hidden w-full h-[2px] rounded-full bg-denim opacity-10 mb-8"></span>
          <h4 className="text-orange">Send Us A Message</h4>
          <p className="my-8">
            If you have specific inquiries or would like to schedule a
            consultation, please use the form below. Our dedicated team will get
            back to you promptly.
          </p>
          <ContactForm />
        </div>
        <div className="mt-24 lg:mt-36 lg:col-span-2">
          <BecomePartner />
        </div>
      </div>
    </div>
  );
};
export default ContactScreen;
