import React from "react";
import { Link } from "react-router-dom";

const FooterNavigationMenu = ({ links }) => {
  return (
    <div className="grid gap-5 md:mx-auto capitalize">
      <h2 className="text-2xl font-bold">Navigation</h2>
      <ul className="flex flex-col gap-4">
        {links
          ?.filter((link) => link.inFooter)
          ?.map((ele, index) => (
            <li
              key={index}
              className="text-white hover:text-orange with-transition"
            >
              <Link to={ele === "homepage" ? "/" : ele.route}>{ele.title}</Link>
            </li>
          ))}
      </ul>
    </div>
  );
};

export default FooterNavigationMenu;
