import React from "react";
import { Link } from "react-router-dom";

const StayRelevant = () => {
  return (
    <div className="flex flex-col gap-4 items-center justify-center text-center text-denim pt-[100px]">
      <h2 className="lg:max-w-[776px] capitalize my-8 lg:mx-auto">
        <span className="text-orange">{`stay relevant`}</span>
      </h2>
      <p className="mb-4 xl:mb-8 lg:max-w-[776px]">
        <span className="font-bold">Your brand is your reputation</span>,
        whether you are a major attraction or a small local institution. We can
        help you maintain the trust of your audience so that you remain always
        their top choice.
      </p>
      <Link
        to="/contact"
        className="bg-orange lg:hover:bg-denim text-white py-3 px-20 w-full lg:w-fit mx-auto block text-center rounded-full font-bold mb-24 lg:mb-36 with-transition"
      >
        Schedule a Meeting
      </Link>
    </div>
  );
};

export default StayRelevant;
