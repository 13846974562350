import React, { useEffect, useState } from "react";

const Toast = ({
  loading,
  subject,
  className,
  success,
  error,
  body,
  delay = 5,
}) => {
  const [display, setDisplay] = useState(success || error);

  useEffect(() => {
    setDisplay(loading || success || error);

    if (success || error) {
      setTimeout(() => setDisplay(false), delay * 1000);
    }

    // eslint-disable-next-line
  }, [body]);

  return display ? (
    <>
      {body ? (
        <div
          className={`z-50 bg-${
            loading || success ? "navy-blue" : error ? "orange" : ""
          } text-white py-2 px-4 fixed top-[50px] ${
            className ? className : ""
          } md:top-[90px] left-[50%] translate-x-[-50%] rounded-md flex gap-2 items-center font-bold`}
        >
          <span>
            {loading
              ? `Loading${subject ? " " + subject : ""}...`
              : success
              ? "Success:"
              : error
              ? "Error:"
              : ""}
          </span>
          <span>{body}</span>
        </div>
      ) : (
        <></>
      )}
    </>
  ) : (
    <></>
  );
};

export default Toast;
