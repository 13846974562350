import analyticsPackage from "../assets/img/services/packages/analytics-package.svg";
import strategicEssentialsPackage from "../assets/img/services/packages/strategic-essentials-package.svg";
import brandGuideDevelopment from "../assets/img/services/packages/brand-guide-development.svg";
import logoDevelopment from "../assets/img/services/packages/logo-development.svg";
import marketingReports from "../assets/img/services/packages/marketing-reports.svg";
import marketingStrategyDevelopment from "../assets/img/services/packages/marketing-strategy-development.svg";
import socialMediaCoordination from "../assets/img/services/packages/social-media-coordination.svg";
import webDesign from "../assets/img/services/packages/web-design.svg";
import arts from "../assets/img/industries/sectors/arts.svg";
import culture from "../assets/img/industries/sectors/culture.svg";
import heritage from "../assets/img/industries/sectors/heritage.svg";
import tourism from "../assets/img/industries/sectors/tourism.svg";

export const servicePackages = [
  {
    icon: strategicEssentialsPackage,
    title: "Strategic Essentials Package",
    description:
      "Craft a compelling visual identity through professional logo design, establish brand guidelines and develop a tailored marketing strategy to amplify your mission with precision and resonance.",
    services: [
      "Logo Development",
      "Brand Guide Development",
      "Marketing Strategy Development",
    ],
    payments: [
      {
        name: "Standard",
        price: "$10,799",
        include: ["Monthly Resources"],
      },
    ],
    btnText: "See plan",
    className: "lg:w-96",
  },
  {
    icon: analyticsPackage,
    title: "Analytics Package",
    description:
      "Self-managed social media armed with a professionally crafted marketing strategy. Receive monthly marketing reports, and with expert feedback to make data-driven decisions.",
    services: ["Marketing Strategy Development", "Monthly Marketing Reports"],
    payments: [
      {
        name: "Standard",
        price: "$796/Month",
        include: ["Monthly Resources"],
      },
    ],
    btnText: "See plan",
    className: "lg:w-96",
  },
];

export const designPackages = [
  {
    icon: brandGuideDevelopment,
    title: "Brand Guide Development",
    description:
      "Craft your brand identity with precision through a collaborative approach that will bring your unique essence out and set out the rules that will clearly showcase who you are.",
    services: [],
    payments: [
      {
        name: "Standard",
        price: "$2,893",
        include: ["Monthly Resources"],
      },
    ],
    btnText: "See plan",
    className: "lg:w-96",
  },
  {
    icon: logoDevelopment,
    title: "Logo Development",
    description:
      "Capture your audience's attention with a distinctive visual symbol that uniquely encapsulates your identity.",
    services: [],
    payments: [
      {
        name: "Standard",
        price: "$2,940",
        include: ["Monthly Resources"],
      },
    ],
    btnText: "See plan",
    className: "lg:w-96",
  },
  {
    icon: webDesign,
    title: "Web Design",
    description:
      "Craft an engaging platform reflecting your identity and mission for optimal digital impact and connection.",
    services: [],
    payments: [
      {
        name: "Standard",
        price: "From $800",
        include: ["Monthly Resources"],
      },
    ],
    btnText: "See plan",
    className: "lg:w-96",
  },
];

export const marketingPackages = [
  {
    icon: marketingStrategyDevelopment,
    title: "Marketing Strategy Development",
    description:
      "Strategize impactful outreach, storytelling, and engagement to amplify your impact and resonate with targeted audiences.",
    services: [],
    payments: [
      {
        name: "Standard",
        price: "$4,966",
        include: ["Monthly Resources"],
      },
    ],
    btnText: "See plan",
    className: "lg:w-96",
  },
  {
    icon: socialMediaCoordination,
    title: "Social Media Coordination",
    description:
      "Optimize your influence with our team managing your social media, fostering engagement, awareness, and community connection.",
    services: [],
    payments: [
      {
        name: "Standard",
        price: "From $447/month",
        include: ["Monthly Resources"],
      },
    ],
    btnText: "See plan",
    className: "lg:w-96",
  },
  {
    icon: marketingReports,
    title: "Marketing Reports",
    description:
      "Receive professional feedback each month to evaluate and make recommendations, enhancing impact.",
    services: [],
    payments: [
      {
        name: "Standard",
        price: "$382/Month",
        include: ["Monthly Resources"],
      },
    ],
    btnText: "See plan",
    className: "lg:w-96",
  },
];

export const links = [
  { title: "Home", route: "/", inHeader: false, inFooter: true },
  { title: "About", route: "/about", inHeader: true, inFooter: true },
  {
    title: "Services",
    route: "/services",
    inHeader: true,
    inFooter: true,
  },
  {
    title: "Industries",
    route: "/industries",
    inHeader: true,
    inFooter: true,
  },
  { title: "Blogs", route: "/blogs", inHeader: true, inFooter: true },
  { title: "Contact", route: "/contact", inHeader: true, inFooter: false },
];

export const divisions = [
  {
    bgColor: "navy-blue",
    heading: "design",
    description:
      "Leverage your brand with visually engaging narratives that resonate with your audience.",
  },
  {
    bgColor: "denim",
    heading: "marketing",
    description:
      "Expand your reach, enhance your engagement, and drive impactful change for your brand.",
  },
  {
    bgColor: "navy-blue",
    heading: "web",
    description:
      "Implement high-end web development and user-friendly interfaces tailored to your brand needs.",
  },
];

export const leads = [
  { name: "miller garcia", title: "design lead" },
  // { name: "luis alban", title: "marketing lead" },
  { name: "mahmoud hassan", title: "technical lead" },
];

export const sectors = [
  {
    icon: tourism,
    heading: "Tourism",
    description:
      "A major driver of global economic activity, accounting for a significant portion of GDP in many countries. It encompasses a wide range of businesses and services, including transportation, accommodation, hospitality, and entertainment. It contributes to infrastructure development and environmental conservation in many destinations.",
  },
  {
    icon: arts,
    heading: "Arts",
    description:
      "Art galleries, museums, theaters, publishing houses, and online platforms play critical roles in promoting and distributing artistic works. The global art market is valued in the billions of dollars, with auctions, exhibitions, and art fairs serving as key venues for society’s economic development.",
  },
  {
    icon: culture,
    heading: "Culture",
    description:
      "Production, distribution, and consumption of cultural goods and services, including music, film, literature, and entertainment. It is a significant contributor to national economies, employing millions of people worldwide and generating substantial revenue through ticket sales, merchandise, licensing, and advertising. Cultural institutions, such as theaters, cinemas, music venues, and publishing houses, play essential roles in supporting artistic and cultural endeavors. The culture industry fosters social cohesion and identity formation by reflecting and shaping societal values, beliefs, and norms.",
  },
  {
    icon: heritage,
    heading: "Heritage",
    description:
      "Focuses on preserving and promoting cultural and historical assets, including museums, archaeological sites, historic landmarks, and cultural events. Encompasses heritage tourism, education, conservation, and research activities. Heritage sites and attractions attract millions of visitors annually, contributing to local economies and raising awareness of the importance of heritage preservation. Public and private organizations collaborate to safeguard heritage resources, employing techniques such as restoration, digitization, and interpretation to ensure their long-term viability and accessibility.",
  },
];

export const morePartners = { count: 41, text: "more" };
export const lessPartners = { count: 24, text: "less" };

export const contact = [
  {
    // icon: PhoneIcon,
    title: "Phone / WhatsApp",
    paragraph: "+ 1 250 569 4401 ext. 108",
  },
  {
    // icon: EmailIcon,
    title: "General Enquiries",
    paragraph: "info@foragerfinancial.com",
  },
  {
    // icon: SalesIcon,
    title: "Sales Enquiries",
    paragraph: "sales@foragerfinancial.com",
  },
  {
    // icon: WorkIcon,
    title: "Work Opportunities",
    paragraph: "careers@foragerfinancial.com",
  },
];

export const networks = [
  {
    name: "facebook",
    href: "https://www.facebook.com/foragerdigital",
    icon: "ri:facebook-fill",
  },
  {
    name: "instagram",
    href: "https://www.instagram.com/foragerdigital",
    icon: "mdi:instagram",
  },
  {
    name: "tiktok",
    href: "https://www.tiktok.com/@foragerdigital",
    icon: "ic:baseline-tiktok",
  },
  {
    name: "linkedin",
    href: "https://www.linkedin.com/company/18914201/admin/feed/posts/",
    icon: "mingcute:linkedin-fill",
  },
];
