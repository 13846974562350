import React from "react";
import { Link } from "react-router-dom";
import useBreakpoints from "../../hooks/useBreakpoints";

import blogs from "../../data/blogs";

const BlogHero = () => {
  const { md } = useBreakpoints();

  return (
    <div className="mt-8 pt-[100px]">
      <h1 className="text-orange mx-auto capitalize mb-8 text-center md:w-[500px] lg:w-[800px]">
        Blogs
      </h1>
      <h3 className="container text-orange mb-4">New Blogs</h3>
      <div
        className={` ${
          !md
            ? "carousel carousel-center py-4 flex gap-4 px-4 md:px-8"
            : "grid grid-cols-3 container gap-6"
        }`}
      >
        {blogs.map((blog, blogIndex) => (
          <Link
            key={blogIndex}
            to={`/blog/${blog.title.toLowerCase().split(" ").join("-")}`}
            className="carousel-item flex-col text-denim hover:text-orange p-4 pb-10 rounded-[30px] lg:rounded-[40px] hover:bg-white with-transition"
          >
            <div
              className="bg-cover bg-no-repeat w-[300px] md:w-full h-[179px] md:h-[224px] rounded-[20px] lg:rounded-[30px]"
              style={{
                backgroundImage: `url("${require(`../../assets/img/blogs/${blog.title.toLowerCase().split(" ").join("-")}.png`)}")`,
              }}
            />
            <p className="w-[300px] lg:w-[310px] xl:w-[320px] font-bold mt-3 lg:text-2xl">
              {blog.title}
            </p>
            <p className="mt-1">{blog.date}</p>
          </Link>
        ))}
      </div>
    </div>
  );
};

export default BlogHero;
