import React from "react";

import useBreakpoints from "../../hooks/useBreakpoints";

import AppSubscribe from "../global/AppSubscribe";

import navyLogo from "../../assets/logos/logo-navy.png";

const HomeNewsletter = () => {
  const { lg } = useBreakpoints();

  return (
    <div
      className="flex items-center justify-center gap-8 pb-24 md:pt-12 md:py-24 md:gap-24 xl:w-[80%] xl:mx-auto rounded-[25px] mt-[100px]"
      style={
        !lg
          ? {
              flexDirection: "column",
              width: "100%",
              paddingTop: "20px",
              paddingBottom: "20px",
            }
          : {}
      }
    >
      <div
        className="w-[540px] h-[575px] -order-1 lg:order-2 bg-navy-blue rounded-[25px]"
        style={
          !lg ? { width: "100%", overflow: "hidden", display: "flex" } : {}
        }
      >
        <AppSubscribe />
      </div>
      <div className="md:order-1">
        <img
          src={navyLogo}
          alt="Logo"
          className="hidden md:block mb-12 max-w-[344px]"
        />
        <h2 className="text-orange text-3xl font-bold">Brand Buzz</h2>
        <span className="block text-navy-blue font-bold mb-8">
          By Forager Digital
        </span>
        <div className="grid gap-5">
          <p className="text-navy-blue font-italic">
            “Inspire, inform, innovate: Your bi-weekly marketing edge.”
          </p>
          <p>
            Every Thursday, you will get an email with 2 marketing tips and 1
            question for you to ponder.
          </p>
          <p>
            If you are looking for tailored marketing solutions for your
            organization’s mission enter your email and sign up for free right
            now.
          </p>
        </div>
      </div>
    </div>
  );
};

export default HomeNewsletter;
