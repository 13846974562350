import React from "react";

const SectorCard = ({ sector, className }) => {
  return (
    <div
      className={`flex flex-col gap-7 py-8 px-5 lg:px-8 bg-navy-blue rounded-3xl ${
        className || ""
      }`}
    >
      <img src={sector.icon} alt={`icon ${sector.heading}`} className="w-12" />
      <h3 className="text-orange text-2xl w-fit text-left">{sector.heading}</h3>
      <p className="text-left text-white">{sector.description}</p>
    </div>
  );
};

export default SectorCard;
