import React from "react";

import tachsector from "../../assets/img/industries/tachsector.svg";

const TachSector = () => {
  return (
    <>
      <h2 className="w-full text-center capitalize my-8 pt-[100px]">
        <span className="text-orange">{`The TACH Sector`}</span>
      </h2>
      <div className="flex flex-col lg:flex-row justify-center items-center gap-16 lg:px-16 mx-auto pb-[50px] lg:max-w-[1080px]">
        <img
          src={tachsector}
          alt="tach-sector"
          className="w-[70%] mx-auto lg:w-[30%] order-first lg:order-last"
        />
        <div className="flex flex-col gap-8 items-start justify-start lg:text-left grow">
          <p>
            Tourism, Arts, Culture, and Heritage (TACH) sector, constitutes a
            pivotal component of societal development. It facilitates economic
            growth through tourism promotion, fosters artistic expression,
            safeguards cultural heritage, and preserves historical legacies.
            This sector serves as a cornerstone for enhancing societal cohesion,
            enriching cultural experiences, and nurturing a sense of identity
            among communities.
          </p>
        </div>
      </div>
    </>
  );
};

export default TachSector;
