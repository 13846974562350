import React from "react";
import { Helmet } from "react-helmet";

const BlogSEO = ({ content }) => {
  return content ? (
    <Helmet>
      <title>{content.title}</title>
      {content.content?.map((excerpt, excerptIndex) => (
        <meta key={excerptIndex} name="description" content={excerpt} />
      ))}
      <meta name="keywords" content={`${content.keywords?.join(", ")}`} />
    </Helmet>
  ) : null;
};

export default BlogSEO;
