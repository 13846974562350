import React from "react";

import StayRelevant from "../components/services/StayRelevant";
import BecomePartner from "../components/global/BecomePartner";
import OurServices from "../components/services/OurServices";

const ServicesScreen = () => {
  return (
    <div className="container">
      <StayRelevant />
      <OurServices />
      <BecomePartner isContact />
    </div>
  );
};

export default ServicesScreen;
