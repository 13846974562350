import React from "react";
import Check from "../../assets/img/services/check.svg";
import { Link } from "react-router-dom";

const OurServicesBuyPlanSuccess = ({ selectedPlan, selectPayment }) => {
  return (
    <div className="">
      <img src={Check} alt="" className="lg:w-[193px] mx-auto mt-20" />
      <h4 className="text-center">
        We have sent you an email with the information
      </h4>
      <Link
        className="bg-crimson-ffs with-transition lg:hover:bg-poly-cyan lg:hover:text-navy-blue-ffs rounded-full py-3 text-white font-bold w-fit px-9 mx-auto mt-8 block"
        to="/"
      >
        Finish
      </Link>
    </div>
  );
};

export default OurServicesBuyPlanSuccess;
