import React, { useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";

import search from "../assets/img/error404/error404.svg";

const ErrorScreen = () => {
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (location.pathname !== "/not-found") {
      navigate(`/not-found`);
    }

    // eslint-disable-next-line
  }, [navigate]);

  return (
    <section className="bg-navy-blue h-screen md:h-full pt-[100px]">
      <div className="flex flex-col md:flex-row-reverse container mx-auto">
        <img
          src={search}
          alt="Error 404"
          className="w-56 md:w-[27.5rem] py-12 md:py-16 mx-auto"
        />
        <div className="lg:px-4 flex flex-col justify-center text-center md:text-start">
          <h1 className="pb-8 font-bold text-4xl md:text-6xl text-white capitalize">
            page not found
          </h1>
          <p className="px-4 md:px-0 pb-9 text-white">
            It looks like this page has moved somewhere else. Come back to the
            homepage
          </p>
          <Link
            to="/"
            className="py-4 px-8 w-fit block mx-auto md:mx-0 capitalize font-bold text-white bg-denim hover:bg-orange rounded-full with-transition"
          >
            back to home
          </Link>
        </div>
      </div>
      <div className="relative container mx-auto">
        <img
          src={require("../assets/img/planets/orange.png")}
          alt="planet orange"
          className="w-8 lg:w-36 right-6 xl:right-[60%] bottom-[30rem] aspect-square absolute with-transition"
        />
        <img
          src={require("../assets/img/planets/white.png")}
          alt="planet cyan"
          className="w-11 lg:w-20 left-6 bottom-96 md:bottom-[26rem] lg:bottom-20 aspect-square absolute with-transition"
        />
        <img
          src={require("../assets/img/planets/orange.png")}
          alt="planet lime"
          className="w-10 lg:w-20 right-6 md:right-2.5 lg:right-0 bottom-72 lg:-bottom-20 xl:bottom-[27rem] aspect-square absolute with-transition"
        />
        <img
          src={require("../assets/img/planets/white.png")}
          alt="planet lime"
          className="w-20 lg:w-12 left-3 lg:left-20 top-12 lg:-top-[34rem] aspect-square absolute with-transition"
        />
        <img
          src={require("../assets/img/planets/white.png")}
          alt="planet white"
          className="w-7 lg:w-14 right-9 lg:right-10 top-6 xl:-top-44 aspect-square absolute with-transition"
        />
        <img
          src={require("../assets/img/planets/orange.png")}
          alt="planet pink"
          className="w-36 lg:w-72 right-20 lg:right-2/4 top-12 lg:-top-72 aspect-square absolute with-transition"
        />
      </div>
    </section>
  );
};

export default ErrorScreen;
