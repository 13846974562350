import React from "react";

import TachSector from "../components/industries/TachSector";
import Passion from "../components/industries/Passion";
import SectorSpecific from "../components/industries/SectorSpecific";
import BecomePartner from "../components/global/BecomePartner";
import ForagerGivesBack from "../components/global/ForagerGivesBack";

const IndustriesScreen = () => {
  return (
    <div className="container">
      <TachSector />
      <Passion />
      <SectorSpecific />
      <BecomePartner isContact />
      <ForagerGivesBack />
    </div>
  );
};

export default IndustriesScreen;
