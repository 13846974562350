import React from "react";

const ForagerGivesBack = () => {
  return (
    <article className="bg-navy-blue rounded-br-[30px] text-white mb-24 lg:mb-36 relative lg:grid lg:grid-cols-12 rounded-[30px] overflow-hidden">
      <div
        className="lg:col-span-5 2xl:col-span-3 bg-contain bg-left-top bg-no-repeat h-52 md:h-72 lg:w-[130%] xl:h-[350px] xl:w-[150%] 2xl:w-[130%] 2xl:h-full"
        style={{
          backgroundImage: `url(${require("../../assets/img/home/hands.png")})`,
        }}
      />
      <div className="col-span-7 2xl:col-span-9 px-4 pb-16 pt-8 lg:py-[74px] lg:pr-[72px]">
        <span className="h-0.5 w-20 rounded-full bg-white block mb-1" />
        <h3 className="font-bold mb-8">Forager Gives Back Program</h3>
        <p className="mb-5">
          <span className="font-bold">1% To Charitable Causes:</span> We believe
          in giving back to the communities that enrich our journey. A
          percentage of our revenue is directed towards charitable causes,
          supporting initiatives that contribute to community development,
          education, and social welfare.
        </p>
        <p>
          <span className="font-bold">1% To Forager Foundation:</span> The
          Forager Foundation plays a pivotal role in creating positive change
          globally. By allocating 1% of our revenue, we contribute to the
          Foundation's mission of supporting projects that make a lasting impact
          on education, environmental sustainability, and community empowerment.
        </p>
      </div>
    </article>
  );
};

export default ForagerGivesBack;
