import React from "react";

import OurStory from "../components/about/OurStory";
import OurJourney from "../components/about/OurJourney";
import OurStrategicPartners from "../components/about/OurStrategicPartners";
import OurPartners from "../components/about/OurPartners";
import BecomePartner from "../components/global/BecomePartner";
import ForagerGivesBack from "../components/global/ForagerGivesBack";

const AboutScreen = () => {
  return (
    <div className="container">
      <OurStory />
      <OurJourney />
      <OurStrategicPartners />
      <OurPartners />
      <BecomePartner isContact />
      <ForagerGivesBack />
    </div>
  );
};

export default AboutScreen;
