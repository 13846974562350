import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import blogs from "../data/blogs";

import BlogHead from "../components/blogs/BlogHead";
import BlogBody from "../components/blogs/BlogBody";
import BlogCTA from "../components/blogs/BlogCTA";
import HomeNewsletter from "../components/home/HomeNewsletter";
import BlogSEO from "../components/blogs/BlogSEO";

const SingleBlogScreen = () => {
  const { slug } = useParams();

  const [blog, setBlog] = useState(null);

  useEffect(() => {
    if (slug) {
      setBlog(
        blogs.find(
          (blog) => blog.title.toLowerCase().split(" ").join("-") === slug,
        ),
      );
    }
  }, [slug]);

  return blog ? (
    <>
      <BlogSEO content={blog.SEO} />
      <div className="container">
        <BlogHead blog={blog} />
        <BlogBody blog={blog} />
        <BlogCTA />
        <HomeNewsletter />
      </div>
    </>
  ) : null;
};

export default SingleBlogScreen;
