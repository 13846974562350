import React from "react";

import { networks } from "../data/data";

const SocialLinks = ({ className, iconClassName }) => {
  return (
    <div className={`flex gap-1 ${className}`}>
      {networks.map((ele, index) => (
        <a
          key={index}
          href={ele.href}
          target="_blank"
          rel="noreferrer"
          className="w-fit"
        >
          <i
            className={`iconify text-2xl mr-0.5 md:mr-2 ${
              iconClassName || ""
            } with-transition`}
            data-icon={ele.icon}
          />
        </a>
      ))}
    </div>
  );
};

export default SocialLinks;
