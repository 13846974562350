import React from "react";

import { links } from "../../data/data";

import whiteLogo from "../../assets/logos/logo-white.png";
import SocialLinks from "../SocialLinks";
import Copyrights from "./Copyrights";
import Circles from "./Circles";
import FooterContactColumn from "./FooterContactColumn";
import FooterNavigationMenu from "./FooterNavigationMenu";

const AppFooter = () => {
  return (
    <footer>
      <div className="bg-denim text-white relative">
        <div className="container grid md:grid-cols-3 gap-12 pt-20 xl:pt-16">
          <div>
            <img src={whiteLogo} alt="Logo" className="w-[240px]" />
            <p className="mt-7 max-w-[447px]">
              A creative agency of expert marketers providing top-tier promotion
              services at affordable rates, enhancing partner operations and
              brand precision.
            </p>
            <p className="mt-7 font-bold">Follow Us</p>
            <SocialLinks
              className="mt-5"
              iconClassName={"text-white hover:text-orange"}
            />
          </div>
          <FooterNavigationMenu links={links} />
          <FooterContactColumn
            city="Gibsons"
            province="BC"
            country="Canada"
            phone="+1 250 569 4401"
            email="info@foragermediagroup.com"
          />
          <Circles />
        </div>
        <Copyrights />
      </div>
    </footer>
  );
};

export default AppFooter;
