import React from "react";

import bcma from "../../assets/img/about/strategic-partners/bcma.svg";
import srcc from "../../assets/img/about/strategic-partners/srcc.svg";

const OurStrategicPartners = () => {
  return (
    <div className="flex flex-col gap-4 items-center justify-center text-center text-denim mb-[50px]">
      <h2 className="lg:max-w-[776px] capitalize lg:mx-auto">
        <span className="text-orange">{`our strategic partners`}</span>
      </h2>
      <div className="flex justify-start lg:justify-center items-start gap-2 w-full overflow-scroll scrollbar-hide">
        <img src={bcma} alt="bcma" className="w-[75%] lg:w-[25%]" />
        <img src={srcc} alt="srcc" className="w-[75%] lg:w-[25%]" />
      </div>
    </div>
  );
};

export default OurStrategicPartners;
