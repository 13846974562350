import React from "react";
import { sectors } from "../../data/data";
import SectorCard from "./SectorCard";

const SectorSpecific = () => {
  return (
    <div className="mb-[100px]">
      <div className="flex flex-col gap-4 items-center justify-center text-center text-denim pt-[100px]">
        <h2 className="capitalize my-8 lg:mx-auto text-left lg:text-center">
          <span className="text-orange">{`Sector-Specific Expertise`}</span>
        </h2>
      </div>
      <div className="w-full flex gap-8 justify-start items-start py-8 overflow-x-scroll scrollbar-hide">
        {sectors.map((sector, sectorIndex) => (
          <SectorCard
            key={sectorIndex}
            sector={sector}
            className="min-w-[300px] lg:w-[22%] lg:grow h-[720px] lg:h-[1024px] xl:h-[720px] xl:w-[22%] xl:grow"
          />
        ))}
      </div>
    </div>
  );
};

export default SectorSpecific;
