import React from "react";
import SocialLinks from "../SocialLinks";

const BlogBody = ({ blog }) => {
  return blog ? (
    <section className="mb-24 md:mb-36">
      <div className="">
        <div className="lg:grid lg:grid-cols-4 lg:gap-8 lg:relative xl:max-w-full xl:mx-auto xl:gap-16">
          {blog.body.map((section, sectionIndex) => (
            <div key={sectionIndex} className="grid gap-8 col-span-5 xl:pr-8">
              {section.heading?.length ? (
                <h4 className="font-bold">
                  {section.heading}:
                </h4>
              ) : (
                <></>
              )}
              {section.description?.length ? (
                <div className="flex flex-col gap-4 mb-8">
                  {section.hero?.length ? (
                    <img src={require(`../../assets/img/blogs/${blog.title.toLowerCase().split(" ").join("-")}.png`)} alt="" className="w-full aspect-normal rounded-[30px]" />
                  ) : (
                    <></>
                  )}
                  {section.description.map((paragraph, paragraphIndex) => (
                    <p
                      className={`${paragraph.startsWith("-") ? "pl-8" : ""}`}
                      key={paragraphIndex}
                    >
                      {paragraph}
                    </p>
                  ))}
                </div>
              ) : (
                <></>
              )}
            </div>
          ))}
          <div className="grid md:hidden place-items-center mt-8 mb-24 md:mb-36">
            <span className="w-full flex md:block h-0.5 rounded-full bg-denim-ffs opacity-20 mb-8" />
            <SocialLinks
              className="mt-5"
              iconClassName={"text-orange hover:text-denim"}
            />
          </div>
        </div>
      </div>
      <span className="hidden md:flex h-0.5 rounded-full bg-denim-ffs opacity-20 mt-14" />
      <div className="hidden md:flex justify-center mt-8 mb-24 md:mb-36">
        <SocialLinks
          className="mt-5"
          iconClassName={"text-orange hover:text-denim"}
        />
      </div>
    </section>
  ) : null;
};

export default BlogBody;
