import React from "react";
import { NavLink, useLocation } from "react-router-dom";

import whiteLogo from "../../assets/logos/logo-white.png";
import navyLogo from "../../assets/logos/logo-navy.png";

function NavegationDesktop({ links, styleNav, logoWhite, styleMenu }) {
  const location = useLocation();

  return (
    <header
      className={`fixed top-0 w-full mb-8 bg-${
        logoWhite ? "navy-blue" : "grey"
      } z-[100]`}
    >
      <div className="container">
        <div className={`w-full z-10 ${styleNav || ""}`}>
          <div className="py-4 flex justify-between items-center w-full">
            <NavLink to="/">
              <img
                src={logoWhite ? whiteLogo : navyLogo}
                alt="Forager Digital"
                className="w-44"
              />
            </NavLink>
            <nav
              className={`font-bold grid place-items-center ${
                styleMenu || ""
              } text-${logoWhite ? "white" : "navy-blue"}`}
            >
              <ul className="flex gap-10 h-full items-center">
                {links
                  ?.filter((link) => link.inHeader)
                  .map((link, linkIndex) => (
                    <li
                      className={`menu-item ${
                        link.route === location?.pathname
                          ? "selected-menu-item"
                          : ""
                      }`}
                      key={linkIndex}
                    >
                      <p
                        className={`with-transition menu-item__line h-0.5 w-1/2 mx-auto lg:mx-0 rounded-full`}
                      />
                      <NavLink
                        to={link.route}
                        className="menu-item__link with-transition"
                      >
                        {link.title}
                      </NavLink>
                    </li>
                  ))}
              </ul>
            </nav>
          </div>
        </div>
      </div>
    </header>
  );
}

export default NavegationDesktop;
