import React from "react";

import BlogHero from "../components/blogs/BlogHero";
import AllBlogs from "../components/blogs/AllBlogs";
import HomeNewsletter from "../components/home/HomeNewsletter";

const BlogsScreen = () => {
  return (
    <div className="container">
      <BlogHero />
      <AllBlogs />
      <HomeNewsletter />
    </div>
  );
};

export default BlogsScreen;
