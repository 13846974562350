import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import useBreakpoints from "../../hooks/useBreakpoints";

import { links } from "../../data/data";

import NavegationDesktop from "./NavegationDesktop";
import AppDrawer from "./AppDrawer";

const AppHeader = ({ styleNav, styleMenu, styleBtn }) => {
  const { lg } = useBreakpoints();

  const location = useLocation();
  const [logoWhite, setLogoWhite] = useState(null);

  useEffect(() => {
    if (location.pathname?.includes("/not-found")) {
      setLogoWhite({});
    } else {
      setLogoWhite(null);
    }
  }, [location]);

  return lg ? (
    <NavegationDesktop
      links={links}
      styleNav={styleNav}
      logoWhite={logoWhite}
      styleMenu={styleMenu}
      styleBtn={styleBtn}
    />
  ) : (
    <AppDrawer links={links} styleNav={styleNav} logoWhite={logoWhite} />
  );
};

export default AppHeader;
