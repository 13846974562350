import React, { useEffect } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

import { redirect } from "./constants/helpers";

import ScrollToTop from "./components/ScrollToTop.js";
import HandleToasts from "./components/HandleToasts";

import SEO from "./components/global/SEO";
import AppHeader from "./components/global/AppHeader";
import AppFooter from "./components/global/AppFooter";

import HomeScreen from "./screens/HomeScreen";
import AboutScreen from "./screens/AboutScreen.jsx";
import ServicesScreen from "./screens/ServicesScreen.jsx";
import IndustriesScreen from "./screens/IndustriesScreen.jsx";
import BlogsScreen from "./screens/BlogsScreen.jsx";
import SingleBlogScreen from "./screens/SingleBlogScreen.jsx";
import ContactScreen from "./screens/ContactScreen.jsx";
import ErrorScreen from "./screens/ErrorScreen.jsx";

const App = () => {
  useEffect(() => {
    redirect();
  });

  return (
    <Router>
      <SEO />
      <ScrollToTop />
      <HandleToasts />
      <AppHeader />
      <Routes>
        <Route path="/" element={<HomeScreen />} exact />
        <Route path="/about" element={<AboutScreen />} exact />
        <Route path="/services" element={<ServicesScreen />} exact />
        <Route path="/industries" element={<IndustriesScreen />} exact />
        <Route path="/blogs" element={<BlogsScreen />} exact />
        <Route path="/blog/:slug" element={<SingleBlogScreen />} exact />
        <Route path="/contact" element={<ContactScreen />} exact />
        <Route path="/not-found" element={<ErrorScreen />} exact />
        <Route path="*" element={<ErrorScreen />} exact />
      </Routes>
      <AppFooter />
    </Router>
  );
};

export default App;
