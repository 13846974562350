import React, { useState } from "react";
import useBreakpoints from "../../hooks/useBreakpoints";

import {
  servicePackages,
  designPackages,
  marketingPackages,
} from "../../data/data";

import { TabContent, TabLabel, TabWrapper } from "../global/AppTabs";
import AppModal from "../global/AppModal";
import OurServicesCard from "./OurServicesCard";
import OurServicesBuyPlan from "./OurServicesBuyPlan";

const OurServices = () => {
  const { md, lg } = useBreakpoints();

  const [selectedPlan, setSelectedPlan] = useState(null);

  function onSelectPlan(planCard) {
    setSelectedPlan(planCard);
  }

  return (
    <>
      <section className="mb-[100px]">
        <div className="xl:max-w-[80%] mx-auto mb-20">
          <h1 className="text-center text-orange">Our Services</h1>
          <p className="my-8 md:text-center max-w-[971px] mx-auto">
            We understand the unique challenges and opportunities that
            organizations face in the TACH sector. Our suite of services is
            crafted to meet your needs and empower your streamline operations.
          </p>
        </div>

        <TabWrapper
          activeTabClass="bg-navy-blue text-white with-transition"
          tabClass="py-4 px-3 rounded-lg"
          value="service-packages"
        >
          <div className="container">
            <div className="grid grid-cols-3 max-w-3xl mx-auto p-0.5 mb-8 border border-gray-400 rounded-xl text-navy-blue font-bold">
              <TabLabel
                name="service-packages"
                className={"hover:bg-navy-blue hover:text-white"}
              >
                Service Packages
              </TabLabel>
              <TabLabel
                name="design"
                className={"hover:bg-navy-blue hover:text-white"}
              >
                Design
              </TabLabel>
              <TabLabel
                name="marketing"
                className={"hover:bg-navy-blue hover:text-white"}
              >
                Marketing
              </TabLabel>
            </div>
          </div>

          <TabContent
            name="service-packages"
            className={`flex ${
              lg ? "flex-nowrap" : "flex-wrap"
            } justify-center gap-5 container`}
          >
            {servicePackages.map((e, i) => (
              <OurServicesCard
                key={i}
                {...e}
                onActionClick={() => onSelectPlan(e)}
              />
            ))}
          </TabContent>
          <TabContent
            name="design"
            className={`flex ${
              lg ? "flex-nowrap" : "flex-wrap"
            } justify-center gap-5 container`}
          >
            {designPackages.map((e, i) => (
              <OurServicesCard
                key={i}
                {...e}
                onActionClick={() => onSelectPlan(e)}
              />
            ))}
          </TabContent>
          <TabContent
            name="marketing"
            className={`flex ${
              lg ? "flex-nowrap" : "flex-wrap"
            } justify-center gap-5 container`}
          >
            {marketingPackages.map((e, i) => (
              <OurServicesCard
                key={i}
                {...e}
                onActionClick={() => onSelectPlan(e)}
              />
            ))}
          </TabContent>
        </TabWrapper>
      </section>
      {selectedPlan && (
        <AppModal
          isOpen
          styleModal={`bg-grey ${!md && "rounded-none min-h-screen"}`}
          styleLabelClose="sticky top-1"
          onClose={() => setSelectedPlan(null)}
          selectedPlan={selectedPlan}
        >
          <OurServicesBuyPlan selectedPlan={selectedPlan} />
        </AppModal>
      )}
    </>
  );
};

export default OurServices;
