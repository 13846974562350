import React, { useEffect, useState } from "react";

const BlogReadingTime = ({ blog, className }) => {
  const [wordCount, setWordCount] = useState(0);

  useEffect(() => {
    if (blog) {
      setWordCount(
        Math.ceil(
          blog.body
            ?.map((block) => block.heading + " " + block.description?.join(" "))
            ?.join(" ")
            ?.split(" ")?.length / 200,
        ) || 0,
      );
    }
  }, [blog]);

  return wordCount ? (
    <p className={`${className}`}>{`${wordCount} minute read!`}</p>
  ) : null;
};

export default BlogReadingTime;
