import React from "react";
import { Link } from "react-router-dom";

import blogs from "../../data/blogs";

const AllBlogs = () => {
  return (
    <div className="mt-24 lg:mt-36 grid gap-8">
      <span className="h-0.5 rounded-full bg-denim opacity-20 block" />
      <div className="lg:w-[80%] lg:mx-auto">
        <div className="flex flex-col md:flex-row md:justify-between">
          <h3 className="text-orange mb-8">All Blogs</h3>
        </div>
        <div className="grid gap-8 mb-8">
          {blogs.map((blog, blogIndex) => (
            <Link
              key={blogIndex}
              to={`/blog/${blog.title.toLowerCase().split(" ").join("-")}`}
              className="md:flex gap-6 text-denim hover:text-orange  p-4 rounded-[20px] hover:bg-white with-transition"
            >
              <div
                className="bg-cover bg-no-repeat h-[179px] md:h-[264] md:w-[275px] rounded-[20px]"
                style={{
                  backgroundImage: `url("${require(`../../assets/img/blogs/${blog.title.toLowerCase().split(" ").join("-")}.png`)}")`,
                }}
              />
              <div>
                <p className="w-[300px] md:w-full font-bold mt-3  md:text-2xl">
                  {blog.title}
                </p>
                <p className="mt-1">{blog.date}</p>
              </div>
            </Link>
          ))}
        </div>
        <span className="h-0.5 rounded-full bg-denim opacity-20 block mb-16" />
      </div>
    </div>
  );
};

export default AllBlogs;
