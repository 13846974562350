import React from "react";

const OurServicesCard = ({
  icon,
  title,
  description,
  btnText,
  className,
  onActionClick = () => {},
}) => {
  return (
    <div
      className={`grid gap-7 py-8 px-5 lg:px-8 border border-gray-400 rounded-3xl w-full ${className}`}
    >
      <img src={icon} alt={`icon ${title}`} className="w-12" />
      <h3 className="text-orange text-2xl">{title}</h3>
      <p>{description}</p>
      <button
        className="mt-auto py-3 text-white font-bold bg-orange hover:bg-denim rounded-full shadow with-transition"
        onClick={() => onActionClick()}
      >
        {btnText}
      </button>
    </div>
  );
};

export default OurServicesCard;
