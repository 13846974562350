import React from "react";
import design from "../../assets/img/design.gif";
import marketing from "../../assets/img/marketing.gif";
import web from "../../assets/img/web.gif";

const DivisionCard = ({ division, className }) => {
  const visuals = [
    { heading: "design", icon: design },
    { heading: "marketing", icon: marketing },
    { heading: "web", icon: web },
  ];

  return division ? (
    <div
      className={`rounded-3xl py-12 px-8 flex gap-8 items-center justify-center bg-${division?.bgColor} text-white ${className}`}
    >
      <img
        src={
          visuals.find((visual) => visual.heading === division.heading)?.icon
        }
        alt={division?.heading}
        className="w-[100px] aspect-square self-start lg:self-center"
      />
      <div className="flex flex-col gap-4 items-start text-left">
        <h3 className="capitalize">{division?.heading}</h3>
        <p>{division?.description}</p>
      </div>
    </div>
  ) : (
    <></>
  );
};

export default DivisionCard;
