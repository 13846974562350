import React, { useState } from "react";
import { NavLink } from "react-router-dom";

import whiteLogo from "../../assets/logos/logo-white.png";
import navyLogo from "../../assets/logos/logo-navy.png";

const AppDrawer = ({ links, styleNav, logoWhite }) => {
  const [drawerShifting, setDrawerShifting] = useState("-300px");
  const [burgerIndex, setBurgerIndex] = useState(20);
  const [overlayDisplay, setOverlayDisplay] = useState("none");
  const [overlayOpacity, setOverlayOpacity] = useState(0);

  const toggleDrawer = (e) => {
    // e.preventDefault();
    if (drawerShifting === "-300px") {
      setDrawerShifting("0");
    } else {
      setDrawerShifting("-300px");
    }

    if (burgerIndex === 20) {
      setBurgerIndex(0);
    } else {
      setBurgerIndex(20);
    }

    if (overlayOpacity === 0) {
      setTimeout(() => {
        setOverlayOpacity(50);
      }, 250);
    } else {
      setOverlayOpacity(0);
    }

    if (overlayDisplay === "none") {
      setOverlayDisplay("block");
    } else {
      setTimeout(() => {
        setOverlayDisplay("none");
      }, 250);
    }
  };

  return (
    <>
      <div
        className="bg-denim w-full h-full fixed top-0 left-0 z-20 mb-8 with-transition"
        style={{ display: overlayDisplay, opacity: `${overlayOpacity}%` }}
        onClick={toggleDrawer}
      ></div>
      <NavLink to="/">
        <img
          src={logoWhite ? whiteLogo : navyLogo}
          alt="Forager Digital"
          className="w-[180px] pl-5 pt-7 absolute top-0 left-0"
        />
      </NavLink>
      <div
        className={`w-12 aspect-square ${
          logoWhite ? "bg-white text-navy-blue" : "bg-navy-blue text-white"
        } fixed right-5 top-5 grid place-content-center rounded-full`}
        style={{ zIndex: burgerIndex }}
        onClick={toggleDrawer}
      >
        <i className="iconify text-4xl" data-icon="majesticons:menu" />
      </div>
      <div
        className="h-full px-6 fixed top-0 w-[300px] with-transition bg-navy-blue z-30"
        style={{ right: drawerShifting }}
      >
        <label className="absolute right-4 top-4" onClick={toggleDrawer}>
          <i
            className="iconify text-2xl text-white absolute right-3 top-4"
            data-icon="heroicons-solid:x"
          />
        </label>
        <ul className="text-white mt-32 w-full capitalize grid gap-8 font-bold">
          {links.map((link, linkIndex) => (
            <li key={linkIndex}>
              <NavLink to={link.route} onClick={toggleDrawer}>
                {link.title}
              </NavLink>
            </li>
          ))}
        </ul>
        <img
          src={require("../../assets/logos/isotipo-full.png")}
          className="w-[120px] mt-32"
          alt=""
        />
      </div>
    </>
  );
};
export default AppDrawer;
