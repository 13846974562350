import React from "react";
import { Link } from "react-router-dom";

const BlogCTA = () => {
  return (
    <div className="grid gap-8 col-span-5 xl:pr-8">
      <p>
        {`If you're looking for tailored marketing solutions and expert guidance
        to support your organization's mission, we invite you to explore our
        range of services. We're here to help you thrive at `}
        <Link
          to="/contact"
          className="font-bold text-orange hover:text-denim with-transition"
        >
          Forager Digital
        </Link>
        .
      </p>
    </div>
  );
};

export default BlogCTA;
