import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { validateEmail } from "../../helpers/validation";

import { createContactMail } from "../../actions/contactMailActions";
import {
  CONTACTMAIL_CREATE_FAIL,
  CONTACTMAIL_CREATE_RESET,
} from "../../constants/contactMailConstants";

const ContactForm = () => {
  const dispatch = useDispatch();

  const contactMailCreate = useSelector((state) => state.contactMailCreate);
  const { error, success } = contactMailCreate;

  const [name, setName] = useState(``);
  const [email, setEmail] = useState(``);
  const [subject, setSubject] = useState(``);
  const [reason, setReason] = useState(``);
  const [message, setMessage] = useState(``);

  const nameHandler = (e) => {
    e.preventDefault();
    setName(e.target.value);
  };

  const emailHandler = (e) => {
    e.preventDefault();
    setEmail(e.target.value);
  };

  const subjectHandler = (e) => {
    e.preventDefault();
    setSubject(e.target.value);
  };

  const reasonHandler = (e) => {
    e.preventDefault();
    setReason(e.target.value);
  };

  const messageHandler = (e) => {
    e.preventDefault();
    setMessage(e.target.value);
  };

  const submitHandler = (e) => {
    e.preventDefault();

    try {
      if (
        !name?.length ||
        !email?.length ||
        !subject?.length ||
        !reason?.length ||
        !message?.length
      ) {
        throw new Error("Fields marked with an * are required!");
      }

      validateEmail(email);

      const contactMail = {
        name,
        email,
        subject,
        reason,
        message,
      };

      dispatch(createContactMail(contactMail));
    } catch (error) {
      dispatch({ type: CONTACTMAIL_CREATE_FAIL, payload: error.message });
    }
  };

  useEffect(() => {
    if (error || success) {
      if (success) {
        setName(``);
        setEmail(``);
        setSubject(``);
        setReason(``);
        setMessage(``);
      }

      setTimeout(() => {
        dispatch({ type: CONTACTMAIL_CREATE_RESET });
      }, 5000);
    }
  }, [error, success, dispatch]);

  return (
    <form onSubmit={submitHandler} className="grid gap-5">
      <p>Fields marked with an * are required</p>
      <label htmlFor="name" className="cursor-pointer">
        <p className="font-bold mb-1">
          Name <span className="text-orange">*</span>
        </p>
        <input
          id="name"
          type="text"
          value={name}
          onChange={nameHandler}
          className="border-[1px] bg-grey border border-denim/30 w-full rounded-lg py-2 px-4 focus:outline-none"
          required
        />
      </label>
      <label htmlFor="email" className="cursor-pointer">
        <p className="font-bold mb-1">
          Email <span className="text-orange">*</span>
        </p>
        <input
          id="email"
          type="email"
          value={email}
          onChange={emailHandler}
          className="border-[1px] bg-grey border border-denim/30 w-full rounded-lg py-2 px-4 focus:outline-none"
          required
        />
      </label>
      <label htmlFor="subject" className="cursor-pointer">
        <p className="font-bold mb-1">
          Subject <span className="text-orange">*</span>
        </p>
        <input
          id="subject"
          type="text"
          value={subject}
          onChange={subjectHandler}
          className="border-[1px] bg-grey border border-denim/30 w-full rounded-lg py-2 px-4 focus:outline-none"
          required
        />
      </label>
      <div>
        <p className="font-bold mb-1">
          Reason for message <span className="text-orange">*</span>
        </p>
        <div className="border-[1px] bg-grey border border-denim/30 w-full rounded-lg py-2 px-4 focus:outline-none cursor-pointer">
          <select
            onChange={reasonHandler}
            className="w-full bg-grey cursor-pointer"
            value={reason}
          >
            <option value="" className="w-full cursor-pointer">
              -- Please Select One --
            </option>
            {[
              "Media Enquiry",
              "Service Enquiry",
              "Partnership Enquiry",
              "Consultation & Advisory",
              "Technical Support",
              "Other",
            ].map((ele, index) => (
              <option key={index} value={ele}>
                {ele}
              </option>
            ))}
          </select>
        </div>
      </div>
      <label htmlFor="message" className="cursor-pointer">
        <p className="font-bold mb-1">
          Message <span className="text-orange">*</span>
        </p>
        <textarea
          id="message"
          onChange={messageHandler}
          className="border-[1px] bg-grey border border-denim/30 w-full rounded-lg py-2 px-4 focus:outline-none h-32"
          value={message}
          maxLength="360"
        />
      </label>
      <button
        className="bg-orange hover:bg-poly-cyan rounded-full py-3 text-white hover:bg-denim font-bold lg:w-fit lg:px-9 with-transition"
        onClick={submitHandler}
      >
        Submit
      </button>
    </form>
  );
};

export default ContactForm;
