import React from "react";

const Circles = () => {
  return (
    <div className="pb-32 md:pb-0">
      <img
        src={require("../../assets/img/planets/orange.png")}
        alt=""
        className="w-32 -top-16 right-4 md:right-20 absolute"
      />
      <img
        src={require("../../assets/img/planets/orange.png")}
        alt=""
        className="w-[69px] absolute md:bottom-32 md:left-72 2xl:left-1/3"
      />
      <img
        src={require("../../assets/img/planets/white.png")}
        alt=""
        className="w-14 absolute bottom-28 right-6 lg:right-40"
      />
      <img
        src={require("../../assets/img/planets/white.png")}
        alt=""
        className="w-5 absolute right-10 top-[22.5rem] md:top-5 md:right-1/3"
      />
    </div>
  );
};

export default Circles;
