import React from "react";
import BlogReadingTime from "./BlogReadingTime";

const BlogHead = ({ blog }) => {
  return blog ? (
    <div className="grid lg:grid-cols-2 items-center gap-8 mb-14 mt-8 pt-[100px]">
      <div>
        <BlogReadingTime
          blog={blog}
          className="w-fit text-white text-center lg:text-left font-bold rounded-full py-3 px-10 mb-4 bg-orange mx-auto lg:mx-0"
        />
        {blog.title?.length ? (
          <h1 className="text-center lg:text-left pr-2 lg:mb-4 text-orange">
            {blog.title}
          </h1>
        ) : (
          <></>
        )}
        <div className="flex gap-4 items-center justify-center lg:justify-start py-2">
          <img
            src={require("../../assets/img/bryce.png")}
            alt=""
            className="w-10 h-10 lg:w-12 lg:h-12 aspect-square"
          />
          <h4 className="text-navy-blue text-xl lg:text-2xl">
            Bryce Mathew Watts
          </h4>
        </div>
        <p className="w-full text-center lg:text-left">{blog.date}</p>
      </div>
      <div
        className="bg-cover bg-no-repeat h-[200px] md:min-h-[344px] md:h-full rounded-[30px]"
        style={{
          backgroundImage: `url("${require(`../../assets/img/blogs/${blog.title
            .toLowerCase()
            .split(" ")
            .join("-")}.png`)}")`,
        }}
      />
    </div>
  ) : null;
};

export default BlogHead;
