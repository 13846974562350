import React from "react";

const FooterContactColumn = ({ city, province, country, phone, email }) => {
  return (
    <div className="grid gap-5 md:mx-auto capitalize">
      <h2 className="text-2xl font-bold">Contact</h2>
      <p className="font-bold">
        Company HQ:
        <span className="block font-normal">{`${city} ${province}, ${country}`}</span>
      </p>
      <p className="font-bold">
        Phone:
        <a
          href={`https://api.whatsapp.com/send?phone=${phone?.replace(/\D/g, "")}`}
          target="_blank"
          rel="noreferrer"
          className="block font-normal text-white hover:text-orange with-transition"
        >
          {phone}
        </a>
      </p>
      <p className="font-bold">
        General Enquiries:
        <a
          href={`mailto:${email}`}
          target="_blank"
          rel="noreferrer"
          className="block font-normal text-white hover:text-orange with-transition lowercase"
        >
          {email}
        </a>
      </p>
    </div>
  );
};

export default FooterContactColumn;
