import React, { useState } from "react";

import { lessPartners, morePartners } from "../../data/data";

const OurPartners = () => {
  const [visible, setVisible] = useState(lessPartners.count);
  const [text, setText] = useState(morePartners.text);

  const visibilityHandler = (e) => {
    e.preventDefault();

    if (visible === lessPartners.count) setVisible(morePartners.count);
    else setVisible(lessPartners.count);

    if (text === morePartners.text) setText(lessPartners.text);
    else setText(morePartners.text);
  };

  return (
    <div className="flex flex-col gap-4 items-center justify-center text-center text-denim mt-[100px] mb-[50px]">
      <h2 className="lg:max-w-[776px] capitalize lg:mx-auto">
        <span className="text-orange">{`our partners`}</span>
      </h2>
      <div className="flex justify-start justify-items-start items-center gap-2 w-full flex-wrap">
        {new Array(morePartners.count).fill(true).map((ele, index) => (
          <img
            key={index + 1}
            src={require(`../../assets/img/about/partners/partner (${
              index + 1
            }).svg`)}
            alt={`partner-${index}`}
            className={`w-[30%] lg:w-[16%] grow ${
              index < visible ? "block" : "hidden"
            }`}
          />
        ))}
      </div>
      <button
        className="bg-denim lg:hover:bg-orange text-white py-3 px-20 w-full lg:w-fit mx-auto block text-center rounded-full font-bold mb-24 lg:mb-36 with-transition capitalize"
        onClick={visibilityHandler}
      >
        see {text}
      </button>
    </div>
  );
};

export default OurPartners;
