import React from "react";
import miller from "../../assets/img/miller.jpg";
import luis from "../../assets/img/luis.jpg";
import mahmoud from "../../assets/img/mahmoud.png";

const LeadCard = ({ lead, className }) => {
  const visuals = [
    { name: "miller garcia", icon: miller },
    { name: "luis alban", icon: luis },
    { name: "mahmoud hassan", icon: mahmoud },
  ];

  return lead ? (
    <div
      className={`rounded-3xl py-4 px-8 flex gap-4 items-center justify-start bg-navy-blue text-white ${className}`}
    >
      <img
        src={
          visuals.find((visual) => visual.name === lead.name)?.icon
        }
        alt={lead?.name}
        className="w-[100px] aspect-square self-start rounded-full"
      />
      <div className="flex flex-col gap-2 items-start text-left">
        <h4 className="capitalize">{lead?.name}</h4>
        <p>{lead?.title}</p>
      </div>
    </div>
  ) : (
    <></>
  );
};

export default LeadCard;
