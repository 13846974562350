import React, { useEffect, useState } from "react";
import useBreakpoints from "../../hooks/useBreakpoints";

const AppSubscribe = () => {
  const { md } = useBreakpoints();
  const [opacity, setOpacity] = useState(0);

  useEffect(() => {
    let myIframe = document.getElementsByClassName("for-emailoctopus");

    setTimeout(() => {
      const objToDestroy =
        myIframe.myiframe.contentDocument.children[0]?.children[1].children[1]
          ?.children[0]?.children[1]?.children[2];
      if (objToDestroy) objToDestroy.style.display = "none";

      setOpacity(100);
    }, 1000);
  });

  return (
    <iframe
      className="for-emailoctopus bg-navy-blue bg-cover bg-no-repeat bg-center rounded-[25px] flex justify-center items-center mx-auto with-transition overflow-clip"
      title="newsletter"
      style={{
        width: md ? "540px" : "540px",
        height: "575px",
        overflow: "auto",
        backgroundColor: "",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        opacity: opacity,
      }}
      name="myiframe"
      id="myiframe"
      // srcDoc={`<html><body><script async src="https://eocampaign1.com/form/93e3341e-f1d1-11ee-a0f8-cd956c522e96.js" data-form="93e3341e-f1d1-11ee-a0f8-cd956c522e96"></script></body></html>`}
      srcDoc={`<html><body><script async src="https://eocampaign1.com/form/06d8bce6-f065-11ee-a697-2544ce720d66.js" data-form="06d8bce6-f065-11ee-a697-2544ce720d66"></script></body></html>`}
    ></iframe>
  );
};

export default AppSubscribe;
