import React from "react";
import { Link } from "react-router-dom";

import { divisions } from "../../data/data";
import DivisionCard from "./DivisionCard";

const HomeServices = () => {
  return (
    <div className="flex flex-col items-center justify-center text-center text-denim mt-[50px] lg:mt-0">
      <h2 className="lg:max-w-[776px] capitalize my-8 lg:mx-auto">
        {`we specialize in producing `}
        <span className="text-orange">{`measurable benefits `}</span>
        {`for `}
        <span className="text-orange">{`your institution`}</span>
      </h2>
      <p className="mb-4 xl:mb-8 lg:max-w-[776px]">
        We are a team of professionals who provide high-quality Marketing,
        Design, and Web development support tailored for the TACH sector
        (tourism, arts, culture and heritage).
      </p>
      <div className="flex gap-8 justify-center items-center py-8 flex-col lg:flex-row">
        {divisions.map((division, index) => (
          <DivisionCard
            key={index}
            division={division}
            className="w-full lg:w-[30%] flex-col lg:flex-row"
          />
        ))}
      </div>
      <Link
        to="/services"
        className="bg-orange lg:hover:bg-denim text-white py-3 px-20 w-full lg:w-fit mx-auto block text-center rounded-full font-bold mb-24 lg:mb-36 with-transition"
      >
        Our Services
      </Link>
    </div>
  );
};

export default HomeServices;
