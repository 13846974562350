import React from "react";
import { leads } from "../../data/data";
import LeadCard from "./LeadCard";

const OurStory = () => {
  return (
    <div className="flex flex-col gap-4 items-center justify-center text-center text-denim py-[100px]">
      <h2 className="lg:max-w-[776px] capitalize my-8 lg:mx-auto">
        <span className="text-orange">{`our story`}</span>
      </h2>
      <p className="mb-4 xl:mb-8 lg:max-w-[776px]">
        Forager Digital started as a content creation specialist back in 2017.
        Since then, we have grown into an international team of innovative
        marketers, branding specialists, UX designers, and graphic designers
        working to support partners in the tourism, arts, culture, and heritage
        (TACH) sector. With our unique specialization, Forager Digital has not
        only helped transform the online presence of numerous partners but has
        also empowered them to connect with audiences in meaningful ways.
      </p>
      <p>Together, we shape the future, one pixel at a time.</p>
      <div className="flex gap-4 justify-center items-center py-8 flex-col lg:flex-row w-full">
        {leads.map((lead, index) => (
          <LeadCard
            key={index}
            lead={lead}
            className="w-full lg:w-[30%] flex-row capitalize"
          />
        ))}
      </div>
    </div>
  );
};

export default OurStory;
