import React from "react";

const Passion = () => {
  return (
    <div className="flex flex-col gap-4 items-center justify-center text-center text-denim pt-[100px]">
      <h2 className="capitalize my-8 lg:mx-auto text-left lg:text-center">
        <span className="text-orange">{`Passion For The TACH Sector`}</span>
      </h2>
      <p className="mb-4 xl:mb-8 lg:max-w-[1080px] text-left lg:text-center">
        In the vast landscape of digital services, specialization is not a
        choice; it’s commitment to understanding the unique nuances and
        challenges different industries face. At Forager Digital we are guided
        by a deep understanding of the TACH sector and the profound impact that
        these organizations have on building communities.
      </p>
    </div>
  );
};

export default Passion;
