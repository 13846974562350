import React from "react";
import journey from "../../assets/img/about/journey.svg";
import { Link } from "react-router-dom";

const OurJourney = () => {
  return (
    <>
      <div className="flex flex-col lg:flex-row gap-8 lg:px-16 mx-auto pb-[50px]">
        <img
          src={journey}
          alt="our-journey"
          className="w-[70%] mx-auto lg:w-[30%] order-first lg:order-last"
        />
        <div className="flex flex-col gap-8 items-start justify-start lg:text-left grow">
          <h2 className="w-full text-center lg:w-fit capitalize mt-4">
            <span className="text-orange">{`our journey`}</span>
          </h2>
          <p>
            As a division of Forager Media Group, Forager Digital is an integral
            part of the growing service offerings of this group of content
            development brands. Originally started by Forager International’s
            founder, Bryce Mathew Watts, Forager Digital has worked closely with
            partners in the TACH sector since day one. We have supported startup
            food brands, family-run restaurants, craft breweries, museums,
            heritage sites, and more over the years, and it is through these
            relationships that we continue to grow as we help our partners grow.
          </p>
          <p>
            Our first strategic partnership with the BC Museums Association in
            2021 is where this new model of partner support rather than simple
            business contracts came to fruition and has been the key to building
            a collaborative network of organizations that all want the same
            thing: to make their voices heard and be recognized by their work.
            Forager Digital’s specialization in branding, marketing, and web has
            meant that we boost partner brands, attract the attention of their
            audiences, and create something that inspires a second look.
          </p>
          <p>
            As the Forager Partnership Program grows, so will the capacity of
            the TACH sector to effectively promote themselves in all manner of
            areas. Our services are designed to take on the technical aspects of
            marketing, design, and web that are known to be important but often
            not possible in-house.
          </p>
        </div>
      </div>
      <Link
        to="/services"
        className="bg-orange lg:hover:bg-denim text-white py-3 px-20 w-full lg:w-fit mx-auto block text-center rounded-full font-bold mb-24 lg:mb-36 with-transition"
      >
        Our Services
      </Link>
    </>
  );
};

export default OurJourney;
